document.addEventListener("DOMContentLoaded", function () {
  var form = document.getElementById('search-formation');
  function rewrite_facet_filters(event) {
    event.preventDefault();

    // Rewrite formation_types
    var boxes = document.getElementsByName('types_de_formation');
    var checked = [];
    for (var i = 0; boxes[i]; ++i) {
      if (boxes[i].checked) {
        checked.push(boxes[i].value);
        boxes[i].setAttribute('disabled', 'disabled');
      }
    }
    var checkedStr = checked.join();
    document.getElementById('_types_de_formation').value = checkedStr;

    // Rewrite formation_sectors
    boxes = document.getElementsByName('secteurs_de_formation');
    checked = [];
    for (i = 0; boxes[i]; ++i) {
      if (boxes[i].checked) {
        checked.push(boxes[i].value);
        boxes[i].setAttribute('disabled', 'disabled');
      }
    }
    checkedStr = checked.join();
    document.getElementById('_secteurs_de_formation').value = checkedStr;
    form.submit();
    return false;
  }
  try {
    form.addEventListener("submit", rewrite_facet_filters, false);
  } catch (e) {
    console.log(e);
  }
});